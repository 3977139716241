import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-purchase-icon',
  templateUrl: './purchase-icon.component.svg',
  styleUrls: [],
})
export class PurchaseIconComponent {
  @Input()
  public color = 'lightgray';
}
