<div class="label">
  <span>{{ text | t }}</span>
  <div class="content">
    <span>{{ content! | t }}</span>
    <img
      *ngIf="!!copyContent"
      (click)="onCopyClick()"
      src="../../../../assets/images/icons/content-copy.svg"
      alt="Copy value"
    />
  </div>
</div>
