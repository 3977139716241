export const delay = (time: number): Promise<void> =>
  new Promise((resolve) => setTimeout(() => resolve(), time));

export const isValidDateString = (value: any): boolean =>
  /^(\d{4})-(\d{2})-(\d{2})([T\s](\d{2}):(\d{2}):(\d{2})(\.(\d+)(Z)?)?)?$/.test(
    value,
  );

export function apiResponseFormatter<T>(obj: T): T {
  if (!obj) return obj;

  if (Array.isArray(obj)) {
    return obj.map((i) => apiResponseFormatter(i)) as any;
  }

  if (typeof obj === 'string' && isValidDateString(obj)) {
    return new Date(obj) as any;
  }

  if (typeof obj === 'object' && !(obj instanceof Date)) {
    return Object.keys(obj).reduce((acc, key) => {
      // @ts-ignore
      acc[key] = apiResponseFormatter(obj[key]);
      return acc;
    }, {}) as T;
  }

  return obj;
}

export const capitalizeFirstLetter = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);
