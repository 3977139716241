import { Injectable } from '@angular/core';
import { dictionary, DictionaryTerm } from '../utils/dictionaries';

export enum Language {
  english = 1,
  portuguese,
}

@Injectable()
export class TranslatorService {
  public language: Language = /^pt\b/.test(navigator.language)
    ? Language.portuguese
    : Language.english;

  public translate = (text: string): string => {
    if (!text) return text;

    return text.toString().split(' ').map(this.translateEach).join(' ');
  };

  private translateEach = (key: string): string => {
    const term = dictionary[key];

    if (!term) {
      return key;
    }

    return this.extractValue(term);
  };

  private extractValue = (term: DictionaryTerm): string =>
    ({
      [Language.portuguese]: term.pt,
      [Language.english]: term.en,
    })[this.language];
}
