import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input()
  public label!: string;

  @Output()
  public onClick = new EventEmitter<void>();

  @Input()
  public disabled = false;

  @Input()
  public loading = false;

  @Input()
  public suffixIcon?: string;

  public handleClick = () => {
    if (this.disabled || this.loading) return;

    this.onClick.emit();
  };
}
