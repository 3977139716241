<div class="field" [class.hasError]="!!error">
  <label>{{ label | t }}</label>

  <input
    inputmode="numeric"
    mask="separator.2"
    [(ngModel)]="val"
    (blur)="blurred()"
    *ngIf="isCurrency"
    [disabled]="disabled"
    [thousandSeparator]="thousandSeparator"
    [decimalMarker]="decimalMarker"
    [prefix]="'R$ '"
    class="isCurrency"
  />
  <input
    [attr.inputmode]="isNumber ? 'numeric' : ''"
    [(ngModel)]="val"
    [mask]="mask"
    (blur)="blurred()"
    *ngIf="!isCurrency"
    [disabled]="disabled"
    [pleaseFocus]="autoFocus"
  />
  <span class="errorInfo">{{ error | t }}</span>
</div>
