<div class="navigation-bar">
  <app-purchase-icon
    [color]="colorPurchase"
    (click)="openPurchase()"
  ></app-purchase-icon>
  <app-statement-icon
    [color]="colorStatement"
    (click)="openStatement()"
  ></app-statement-icon>
</div>
