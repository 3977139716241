<app-field
  label="signUp.postalCodeLabel"
  type="number"
  [(value)]="postalCode"
  mask="00.000-000"
></app-field>
<app-field
  label="signUp.streetNameLabel"
  [(value)]="streetName"
  [disabled]="loadingAddressInfo"
  [autoFocus]="false"
></app-field>
<app-field
  label="signUp.addressNumberLabel"
  [(value)]="addressNumber"
  [disabled]="loadingAddressInfo"
  [autoFocus]="false"
></app-field>
