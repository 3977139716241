import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-statement-icon',
  templateUrl: './statement-icon.component.svg',
  styleUrls: [],
})
export class StatementIconComponent {
  @Input()
  public color: string = 'lightgray';
}
