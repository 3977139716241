export const isValidName = (name: string): boolean =>
  name.trim().split(' ').length > 1;

export const phoneNumberIsValid = (n: string): boolean => n.length === 11;

export const emailIsValid = (email: string): boolean =>
  !!email
    .trim()
    .match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

const oneHundredYearsAgo = -1486080000000;

export const isValidPastDate = (dt: string): boolean => {
  const parsedDate = parseDate(dt);

  if (!parsedDate || parsedDate.toString() === 'Invalid Date') return false;

  const time = parsedDate.getTime();
  return !!time && time < Date.now() && time > oneHundredYearsAgo;
};

export const parseDate = (dt: string): Date | undefined => {
  if (!dt) return;

  const day = dt.substring(0, 2);
  const month = dt.substring(2, 4);
  const year = dt.substring(4);
  if (!day || !month || !year) return new Date('foo');

  const strDate = `${year}-${month}-${day}`;
  return new Date(strDate);
};
