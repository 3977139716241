import { Pipe, PipeTransform } from '@angular/core';

type LocalizedOption = 'onlyDate' | 'onlyTime' | 'dateTime';

@Pipe({
  name: 'localizedDate',
})
export class LocalizedDatePipe implements PipeTransform {
  public transform(
    value: Date | string | null | undefined,
    option: LocalizedOption = 'dateTime',
  ): string {
    if (!value) return '';

    const date = new Date(value);
    const lang = navigator.language;

    switch (option) {
      case 'onlyDate':
        return date.toLocaleDateString(lang, {
          day: '2-digit',
          month: '2-digit',
          year: '2-digit',
        });
      case 'onlyTime':
        return date.toLocaleTimeString(lang, {
          hour: '2-digit',
          minute: '2-digit',
        });
      case 'dateTime':
        return date.toLocaleString(lang, {
          day: '2-digit',
          month: '2-digit',
          year: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
        });
    }
  }
}
