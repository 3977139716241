import { map, Observable } from 'rxjs';
import { AddressInfo } from '../types/address';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

interface ViaCepResponse {
  cep: string;
  logradouro: string;
  complemento?: string;
  bairro: string;
  localidade: string;
  uf: string;
}

@Injectable()
export class ViaCepService {
  constructor(private http: HttpClient) {}

  public getAddressInfo = (postalCode: string): Observable<AddressInfo> => {
    const url = this.makeUrl(postalCode);

    return this.http.get<ViaCepResponse>(url).pipe(
      map((x) => ({
        postalCode: x.cep,
        street: x.logradouro,
        neighborhood: x.bairro,
        city: x.localidade,
        state: x.uf,
        number: '',
        complement: x.complemento,
      })),
    );
  };

  private makeUrl = (cep: string): string =>
    `https://viacep.com.br/ws/${cep}/json`;
}
