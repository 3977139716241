import { Component } from '@angular/core';
import { Order, OrderStatus } from '../models/types/order';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public order: Order = {
    address: 'TRBzn9JZnFr44YC3SCqyEigL4TW1hsygDq',
    amount: 1,
    createdAt: new Date(2022, 12, 12, 15, 32),
    status: OrderStatus.created,
  } as any as Order;
}
