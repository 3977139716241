import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TranslatorService } from '../services/TranslatorService';

export interface AlertPayload {
  message: string;
  createdAt: Date;
  type: 'success' | 'error';
}

@Injectable()
export class AlertStore {
  private readonly _lastAlert = new BehaviorSubject<AlertPayload | undefined>(
    undefined,
  );
  public readonly lastAlert$ = this._lastAlert.asObservable();

  private readonly _toast = new BehaviorSubject<string | undefined>(undefined);
  public readonly toast$ = this._toast.asObservable();

  constructor(private translator: TranslatorService) {}

  public alertSuccess = (message: string) => {
    this._lastAlert.next({
      message,
      createdAt: new Date(),
      type: 'success',
    });
  };

  public alertError = (message: string) => {
    this._lastAlert.next({
      message,
      createdAt: new Date(),
      type: 'error',
    });
  };

  public showToast = (toast: string) => {
    this._toast.next(this.translator.translate(toast));
  };

  public showCopiedToClipBoardToast = (label: string) => {
    this.showToast(`${label} general.copiedToClipBoard`);
  };
}
