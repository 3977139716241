import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss'],
})
export class ErrorModalComponent {
  @Input() errorMessage = '';
  @Input() showErrorModal = false;

  constructor() {}

  closeModal() {
    this.showErrorModal = false;
  }
}
