import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AddressInfo } from '../../../../models/types/address';
import { finalize } from 'rxjs';
import { ViaCepService } from '../../../../models/services/ViaCepService';

@Component({
  selector: 'app-address-field',
  templateUrl: './address-field.component.html',
  styleUrls: ['./address-field.component.scss'],
})
export class AddressFieldComponent {
  public loadingAddressInfo = false;

  private _postalCode = '';
  private _addressNumber = '';
  private _streetName = '';

  private addressInfo?: AddressInfo;

  @Output()
  public loading = new EventEmitter<boolean>();

  @Input()
  public set address(val: AddressInfo | undefined) {
    if (val === this.addressInfo || !val) return;

    this.addressInfo = val;
    this._postalCode = val?.postalCode || '';
    this._addressNumber = val?.number || '';
    this._streetName = val?.street || '';
  }

  @Output()
  public addressChange = new EventEmitter<AddressInfo | undefined>();

  private propagateValue = () => {
    if (
      !this.postalCode ||
      !this.addressNumber ||
      !this.streetName ||
      !this.addressInfo ||
      this.postalCode.length < 8
    ) {
      this.addressChange.emit();
      return;
    }

    this.addressChange.emit({
      postalCode: this.postalCode,
      street: this.streetName,
      number: this.addressNumber,
      neighborhood: this.addressInfo.neighborhood,
      city: this.addressInfo.city,
      state: this.addressInfo.state,
      complement: this.addressInfo.complement,
    });
  };

  constructor(private viaCep: ViaCepService) {}

  public get postalCode(): string {
    return this._postalCode;
  }

  public set postalCode(val: string) {
    this._postalCode = val;
    this.searchAddressByPostalCode();
    this.propagateValue();
  }

  public get streetName(): string {
    return this._streetName;
  }

  public set streetName(val: string) {
    this._streetName = val;
    this.propagateValue();
  }

  public get addressNumber(): string {
    return this._addressNumber;
  }

  public set addressNumber(val: string) {
    this._addressNumber = val;
    this.propagateValue();
  }

  private searchAddressByPostalCode = (): void => {
    if (this.postalCode.length !== 8) return;

    this.loadingAddressInfo = true;
    this.addressInfo = undefined;
    this.viaCep
      .getAddressInfo(this.postalCode)
      .pipe(
        finalize(() => {
          this.loadingAddressInfo = false;
        }),
      )
      .subscribe((x) => {
        if (!x) return;

        this.addressInfo = x;
        this.streetName = x.street;
      });
  };
}
