import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-text-button',
  templateUrl: './text-button.component.html',
  styleUrls: ['./text-button.component.scss'],
})
export class TextButtonComponent {
  @Input()
  public label!: string;

  @Input()
  public disabled = false;

  @Output()
  public onClick = new EventEmitter<void>();

  public handleClick = () => {
    if (this.disabled) return;

    this.onClick.emit();
  };
}
