<div class="bank-info">
    <app-logo></app-logo>

    <span>{{'signUp.successFirstPart' | t}}</span>
    <span>{{'signUp.successSecondPart' | t}}</span>

    <div class="deposit-details">
        <div>
            <img src="assets/images/bb.jpg" alt="" />
            <span><b>Banco do Brasil</b> (001)</span>
            <span><b>AG:</b> 4135</span>
            <span><b>CC:</b> 13109-1</span>
        </div>

        <div>
            <span>Pix</span>
            <app-qr-code-pix address="bb@dlmotc.com"></app-qr-code-pix>
            <span>bb@dlmotc.com</span>
        </div>
    </div>
</div>