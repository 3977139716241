import { Injectable } from '@angular/core';
import { Api } from './Api';
import { map, Observable } from 'rxjs';
import { getMakeUrl } from '../utils/generateMakeUrl';
import { environment } from '../../environments/environment';
import { CreateClientDto } from '../types/client';
import { AddressInfo } from '../types/address';
import { ResponseSearchClient } from '../types/responseSearchClient';

export interface PayloadVerifyHasAccount {
  document?: string;
  phoneNumber?: string;
  email?: string;
}

@Injectable()
export class ClientService {
  constructor(private api: Api) {}

  private makeUrl = getMakeUrl(environment.clientApi);

  public verifyHasAccount = (
    obj: PayloadVerifyHasAccount,
  ): Observable<null> => {
    return this.api
      .post<Object>(this.makeUrl('kyc/verify-client-exists'), obj)
      .pipe(map((x) => null));
  };

  public createAccount = (payload: CreateClientDto): Observable<void> => {
    return this.api.post<void>(this.makeUrl('kyc/create-client'), payload);
  };

  public searchClientInfo = (
    document: string,
  ): Observable<ResponseSearchClient> => {
    return this.api.get<ResponseSearchClient>(
      this.makeUrl('kyc/search-info', document),
      false,
    );
  };
}
