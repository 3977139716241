import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FieldComponent } from './components/utils/field/field.component';
import { NgxMaskModule } from 'ngx-mask';
import { DocumentFieldComponent } from './components/utils/document-field/document-field.component';
import { FormsModule } from '@angular/forms';
import { TranslatorPipe } from '../models/pipes/translator.pipe';
import { ClientService } from '../models/services/ClientService';
import { Api } from '../models/services/Api';
import { HttpClientModule } from '@angular/common/http';
import { TranslatorService } from '../models/services/TranslatorService';
import { AlertStore } from '../models/store/AlertStore';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { OnboardingComponent } from './components/onboarding/onboarding.component';
import { TextButtonComponent } from './components/utils/text-button/text-button.component';
import { ButtonComponent } from './components/utils/button/button.component';
import { LogoComponent } from './components/utils/logo/logo.component';
import { ViaCepService } from '../models/services/ViaCepService';
import { FocusDirective } from './directives/focus.directive';
import { ClientStore } from '../models/store/ClientStore';
import { LoadingComponent } from './components/utils/loading/loading.component';
import { AppBarComponent } from './components/layout/app-bar/app-bar.component';
import { AlertComponent } from './components/layout/alert/alert.component';
import { NavigationBarComponent } from './components/layout/navigation-bar/navigation-bar.component';
import { StatementIconComponent } from './components/utils/icons/statement-icon/statement-icon.component';
import { PurchaseIconComponent } from './components/utils/icons/purchase-icon/purchase-icon.component';
import { FullPageComponent } from './components/layout/full-page/full-page.component';
import { LabelComponent } from './components/utils/label/label.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { LocalizedDatePipe } from '../models/pipes/localized-date.pipe';
import { QRCodeModule } from 'angularx-qrcode';
import { AddressFieldComponent } from './components/utils/address-field/address-field.component';
import { IconButtonComponent } from './components/utils/icon-button/icon-button.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ToastComponent } from './components/utils/toast/toast.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { WalletAddressComponent } from './components/utils/wallet-address/wallet-address.component';
import { BankInfoComponent } from './components/bank-info/bank-info.component';
import { QrCodePixComponent } from './components/utils/qr-code-pix/qr-code-pix.component';
import { ErrorModalComponent } from './components/error-modal/error-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    FieldComponent,
    DocumentFieldComponent,
    TranslatorPipe,
    OnboardingComponent,
    TextButtonComponent,
    ButtonComponent,
    LogoComponent,
    FocusDirective,
    LoadingComponent,
    AppBarComponent,
    AlertComponent,
    NavigationBarComponent,
    StatementIconComponent,
    PurchaseIconComponent,
    FullPageComponent,
    LabelComponent,
    LocalizedDatePipe,
    AddressFieldComponent,
    IconButtonComponent,
    ToastComponent,
    WalletAddressComponent,
    BankInfoComponent,
    QrCodePixComponent,
    ErrorModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxMaskModule.forRoot(),
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatDialogModule,
    ClipboardModule,
    QRCodeModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  providers: [
    TranslatorPipe,
    ClientStore,
    ClientService,
    Api,
    TranslatorService,
    AlertStore,
    ViaCepService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
