import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  Language,
  TranslatorService,
} from '../../../../models/services/TranslatorService';

type InputType = 'text' | 'number' | 'currency';

@Component({
  selector: 'app-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FieldComponent),
      multi: true,
    },
  ],
})
export class FieldComponent {
  private _val = '';

  @Input()
  public label!: string;

  @Input()
  public mask?: string;

  @Input()
  public type: InputType = 'text';

  @Input()
  public error?: string;

  @Input()
  public set value(val: string) {
    this._val = val;
  }

  @Input()
  public autoFocus = false;

  @Input()
  public disabled = false;

  @Output()
  public valueChange = new EventEmitter<string>();

  @Output()
  public onBlur = new EventEmitter<boolean>();

  constructor(private translator: TranslatorService) {}

  public get val(): string {
    return this._val;
  }

  public set val(v: string) {
    this._val = v;
    this.valueChange.emit(v);
  }

  public blurred = () => {
    this.onBlur.emit();
  };

  public get isCurrency(): boolean {
    return this.type === 'currency';
  }

  public get isNumber(): boolean {
    return this.type === 'number';
  }

  public get thousandSeparator(): string {
    return {
      [Language.english]: ',',
      [Language.portuguese]: '.',
    }[this.translator.language];
  }

  public get decimalMarker(): '.' | ',' {
    const dic: Record<Language, '.' | ','> = {
      [Language.english]: '.',
      [Language.portuguese]: ',',
    };

    return dic[this.translator.language];
  }
}
