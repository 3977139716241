export enum ClientType {
  individual = 'individual',
  business = 'business',
}

export interface AddressDto {
  postalCode: string;
  street: string;
  number: string;
  neighborhood: string;
  city: string;
  state: string;
  complement?: string;
}

export interface CreateClientDto {
  document: string;
  name: string;
  email: string;
  phoneNumber: string;
  birthDate?: Date;
  publicPerson: boolean;
  address: AddressDto;
  wallet: string;
}
