export type DictionaryTerm = {
  pt: string;
  en: string;
};

export const dictionary: { [key: string]: DictionaryTerm } = {
  ['signIn.documentLabel']: { pt: 'CPF/CNPJ', en: 'CPF/CNPJ' },
  ['signIn.go']: { pt: 'entrar', en: 'GO' },

  ['signUp.title']: { pt: 'Cadastro', en: 'Sign Up' },
  ['signUp.individualNameLabel']: { pt: 'Seu nome', en: 'Your name' },
  ['signUp.companyNameLabel']: {
    pt: 'Nome da sua empresa',
    en: 'Company name',
  },
  ['signUp.phoneNumberLabel']: { pt: 'Celular', en: 'Phone Number' },
  ['signUp.emailLabel']: { pt: 'Email', en: 'Email' },
  ['signUp.birthDateLabel']: { pt: 'Data de Nascimento', en: 'Birth Date' },
  ['signUp.postalCodeLabel']: { pt: 'CEP', en: 'Postal Code' },
  ['signUp.streetNameLabel']: { pt: 'Endereço', en: 'Street Name' },
  ['signUp.addressNumberLabel']: { pt: 'Número', en: 'Number' },
  ['signUp.walletAddress']: {
    pt: 'Endereço Carteira de Crypto',
    en: 'Crypto wallet address',
  },
  ['signUp.createAccount']: { pt: 'Criar conta', en: 'Create Account' },
  ['signUp.labelNextStep']: { pt: 'Próximo', en: 'Next' },
  ['signUp.acceptedTermPrefix']: { pt: 'Aceito os', en: 'I accept the' },
  ['signUp.acceptedTermLink']: { pt: 'termos', en: 'terms' },
  ['signUp.acceptedTermSuffix']: { pt: 'de uso', en: 'of use' },
  ['signUp.acceptedLGPDPrefix']: {
    pt: 'Aceito os termos da',
    en: 'I accept the',
  },
  ['signUp.acceptedLGPDLink']: { pt: 'lgpd', en: 'lgpd' },
  ['signUp.acceptedLGPDSuffix']: { pt: '', en: 'terms' },
  ['signUp.successFirstPart']: {
    pt: 'Cadastro realizado.',
    en: 'Registeres Successfully. Now you can deposit in one of the accounts below.',
  },
  ['signUp.successSecondPart']: {
    pt:
      'Agora é só depositar em qualquer conta abaixo que receberá na carteira indicada em seu\n' +
      '        cadastro!',
    en: 'After that you will receive on the registeres wallet address',
  },
  ['general.takeAnotherPicture']: { pt: 'Tirar outra', en: 'Try again' },
  ['general.save']: { pt: 'Salvar', en: 'Save' },
  ['general.confirm']: { pt: 'Confirmar', en: 'Confirm' },

  ['error.unknown']: { pt: 'Erro desconhecido', en: 'Unknown Error' },
  ['error.clientAlreadyExists']: {
    pt: 'Cliente já foi cadastrado',
    en: 'Client already exists',
  },
  ['error.emailAlreadyRegistered']: {
    pt: 'Email já cadastrado',
    en: 'Email already registered',
  },
  ['error.phoneNumberAlreadyRegistered']: {
    pt: 'Telefone já cadastrado',
    en: 'Phone number already registered',
  },
  ['error.document.required']: {
    pt: 'Documento é obrigatório',
    en: 'Document is required',
  },
  ['error.document.invalidCompanyStatus']: {
    pt: 'O status da empresa não é valido para o cadastro. A empresa deve estar ativa para continuar.',
    en: 'Invalid company status to register. The company must be active to continue.',
  },
  ['error.document.minimumAge']: {
    pt: 'A idade mínima para se cadastrar é 18 anos',
    en: 'Minimum age to register is 18 years old.',
  },
  ['error.document.invalidIndividual']: {
    pt: 'CPF inválido',
    en: 'Invalid CPF',
  },
  ['error.document.invalidCompany']: {
    pt: 'CNPJ inválido',
    en: 'Invalid CNPJ',
  },
  ['error.document.invalid']: {
    pt: 'Documento inválido',
    en: 'Invalid Document',
  },
  ['error.address.required']: {
    pt: 'Endereço é obrigatório',
    en: 'Address is required',
  },
  ['error.address.invalid']: {
    pt: 'Endereço inválido. Deve ser da rede Ethereum ou Tron',
    en: 'Address must be a Ethereum or Tron valid address',
  },
  ['error.wallet.required']: {
    pt: 'Carteira é obrigatória',
    en: 'Wallet is required',
  },
  ['error.wallet.invalid']: {
    pt: 'Carteira precisa ser um endereço Ethereum, Tron ou Bitcoin válido',
    en: 'Invalid wallet address. Must be a ETH, Tron or BTC address',
  },
};
