<div class="onboarding">
  <app-loading class="loading" *ngIf="loading"></app-loading>
  <app-logo></app-logo>

  <app-document-field [(document)]="document" *ngIf="step === 0" [autoFocus]="true"
    label="signIn.documentLabel"></app-document-field>

  <app-wallet-address [(walletAddress)]="walletAddress" *ngIf="step === 0"
    label="signUp.walletAddress"></app-wallet-address>

  <app-field [label]="labelName" [disabled]="locked" [(value)]="name" *ngIf="step === 1"></app-field>

  <app-field label="signUp.birthDateLabel" [(value)]="birthDate" *ngIf="step === 1 && !isBusiness" [disabled]="locked"
    type="number" mask="00/00/0000"></app-field>

  <app-field label="signUp.emailLabel" [(value)]="email" *ngIf="step === 1"></app-field>

  <app-field label="signUp.phoneNumberLabel" [(value)]="phoneNumber" type="number" *ngIf="step === 1"
    mask="(00) 0 0000-0000"></app-field>

  <app-address-field (addressChange)="setAddress($event)" (loading)="(loadingAddressInfo)" [address]="address"
    *ngIf="step === 1"></app-address-field>

  <app-error-modal [errorMessage]="errorMessage" [showErrorModal]="showErrorModal"></app-error-modal>

  <span *ngIf="step === 1">
    <input type="checkbox" [(ngModel)]="acceptedTerm" />
    {{ 'signUp.acceptedTermPrefix' | t }}
    <a href="/terms" target="_blank">{{ 'signUp.acceptedTermLink' | t }}</a>
    {{ 'signUp.acceptedTermSuffix' | t }}
  </span>

  <span *ngIf="step === 1">
    <input type="checkbox" [(ngModel)]="acceptedLgpd" />
    {{ 'signUp.acceptedLGPDPrefix' | t }}
    <a href="/lgpd" target="_blank">{{ 'signUp.acceptedLGPDLink' | t }}</a>
    {{ 'signUp.acceptedLGPDSuffix' | t }}
  </span>

  <app-button class="action" label="signUp.labelNextStep" (onClick)="nextStep()" [disabled]="!canStepUp"
    *ngIf="!isLastStep" [loading]="loading || loadingAddressInfo"></app-button>

  <app-button class="action" label="signUp.createAccount" (onClick)="createAccount()" [disabled]="!lastStepIsValid"
    *ngIf="isLastStep" [loading]="loading || loadingAddressInfo"></app-button>
</div>