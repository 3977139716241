import { Component, OnDestroy, OnInit } from '@angular/core';
import { AlertStore } from '../../../../models/store/AlertStore';
import { Subscription } from 'rxjs';

const timeLivespanAlert = 4;

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnInit, OnDestroy {
  public toast?: string;
  public show = false;
  private alertSubscription?: Subscription;
  private timeoutPointer?: NodeJS.Timeout;

  constructor(private alertStore: AlertStore) {}

  public ngOnInit(): void {
    this.alertSubscription = this.alertStore.toast$.subscribe((x) => {
      this.toast = x;

      if (!x) return;

      if (!!this.timeoutPointer) clearTimeout(this.timeoutPointer);

      this.show = true;
      this.timeoutPointer = setTimeout(() => {
        this.show = false;
      }, timeLivespanAlert * 1000);
    });
  }

  public ngOnDestroy(): void {
    this.alertSubscription?.unsubscribe();
  }
}
