import { HttpErrorResponse } from '@angular/common/http';

const errorMessages = new Map();

errorMessages.set('duplicated-document', 'error.clientAlreadyExists');
errorMessages.set('duplicated-email', 'error.emailAlreadyRegistered');
errorMessages.set(
  'duplicated-phone-number',
  'error.phoneNumberAlreadyRegistered',
);

const getErrorMessage = (errorCode: string): string =>
  errorMessages.get(errorCode) || 'error.unknown';
export const getMessageFromError = (e: HttpErrorResponse): string =>
  getErrorMessage(e?.error?.message);
