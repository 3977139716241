import { Component, OnInit } from '@angular/core';
import { ClientType, CreateClientDto } from '../../../models/types/client';
import { AddressInfo } from '../../../models/types/address';
import { catchError, finalize, map, switchMap, Observable, of } from 'rxjs';
import {
  emailIsValid,
  isValidName,
  isValidPastDate,
  parseDate,
  phoneNumberIsValid,
} from '../../../models/utils/validators';
import { FillDocumentType } from '../utils/document-field/document-field.component';
import { ClientStore } from '../../../models/store/ClientStore';
import { ActivatedRoute, Router } from '@angular/router';
import { validCpfOrCnpj } from '../../../models/utils/cpfCnpjValidator';
import { calculateAge } from 'src/models/utils/calculateAge';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
})
export class OnboardingComponent implements OnInit {
  public readonly individual = FillDocumentType.individual;

  public errorMessage = '';
  public showErrorModal = false;

  public step = 0;

  public document = '';
  public companyStatus = '';
  public name = '';
  public birthDate = '';
  public minimunAge = true;
  public publicPerson: boolean = false;
  public message: string = '';

  public locked = false;

  public email = '';
  public phoneNumber = '';
  public address?: AddressInfo;

  public walletAddress = '';
  public acceptedTerm = false;
  public acceptedLgpd = false;

  public loading = false;
  private _loadingAddressInfo = false;

  public get loadingAddressInfo(): boolean {
    return this._loadingAddressInfo;
  }

  public get canStepUp(): boolean {
    return this.fieldValidators[this.step]();
  }

  public get isLastStep(): boolean {
    return this.lastStep === this.step;
  }

  public get isBusiness(): boolean {
    return this.documentType === ClientType.business;
  }

  public get lastStepIsValid(): boolean {
    return this.fieldValidators[this.lastStep]();
  }

  private get documentType(): ClientType {
    return this.document.length === 14
      ? ClientType.business
      : ClientType.individual;
  }

  private get lastStep(): number {
    return 1;
  }

  public validateCompanyStatus(): boolean {
    return this.companyStatus === 'ATIVA';
  }

  public validateAge(birthDate: Date): boolean {
    const age = calculateAge(birthDate);
    return age < 18;
  }

  private get fieldValidators(): (() => boolean)[] {
    return [
      () => validCpfOrCnpj(this.document) && !!this.walletAddress,
      () => {
        const birthDateIsValid =
          this.isBusiness || isValidPastDate(this.birthDate);
        return (
          birthDateIsValid &&
          phoneNumberIsValid(this.phoneNumber) &&
          !!this.address &&
          emailIsValid(this.email) &&
          isValidName(this.name) &&
          this.acceptedTerm &&
          this.acceptedLgpd
        );
      },
    ];
  }

  public setAddress(val: AddressInfo | undefined) {
    this.address = val;
  }

  constructor(
    private clientStore: ClientStore,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    const urlSegment = this.route.snapshot.url;
    if (urlSegment.length > 0) {
      this.walletAddress = urlSegment[0].path;
    }
  }

  public verifyHasAccount = (): Observable<null> => {
    this.loading = true;
    return this.clientStore.verifyHasAccount(this.document).pipe(
      switchMap(() => {
        return this.clientStore.searchClientInfo(this.document).pipe(
          map((val) => {
            if (!val) {
              this.locked = false;
              return null;
            }
            this.locked = true;
            this.name = val.name;
            this.birthDate = '';
            if (!!val.birthDate) {
              const validAge = this.validateAge(val.birthDate);
              const [year, month, date] = val.birthDate
                ?.toISOString()
                .substring(0, 10)
                .split('-');
              this.birthDate = `${date}${month}${year}`;
              if (!validAge || this.birthDate == '') {
                this.minimunAge = false;
              }
            }
            this.publicPerson = val.importantPerson || false;
            this.address = val.address;
            this.email = val.email || '';
            this.companyStatus = val.companyStatus || '';
            this.message = val.message || '';
            return null;
          }),
          catchError((err) => {
            if (err.error.message === 'invalid-document') {
              this.showErrorModal = true;
              this.errorMessage = 'error.document.minimumAge';
            }
            if (err.error.message === 'invalid-company-status') {
              this.showErrorModal = true;
              this.errorMessage = 'error.document.invalidCompanyStatus';
            }
            if (err.error.message === 'timeout') {
              this.step++;
              this.locked = false;
            }
            throw err;
          }),
        );
      }),
      finalize(() => {
        this.loading = false;
      }),
    );
  };

  public nextStep = (): void => {
    this.verifyHasAccount().subscribe(() => {
      this.step++;
    });
  };

  public get labelName(): string {
    const dic = {
      [ClientType.individual]: 'signUp.individualNameLabel',
      [ClientType.business]: 'signUp.companyNameLabel',
    };

    return dic[this.documentType] || dic[ClientType.individual];
  }

  public createAccount = (): void => {
    this.loading = true;
    this.clientStore
      .verifyHasAccount(this.document)
      .pipe(
        switchMap(() => this.clientStore.createAccount(this.getClientData())),
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe(() => {
        this.router.navigate(['/bank-info']).then();
      });
  };

  private getClientData(): CreateClientDto {
    return {
      document: this.document,
      name: this.name.trim(),
      email: this.email.trim(),
      phoneNumber: this.phoneNumber.trim(),
      birthDate: parseDate(this.birthDate),
      publicPerson: this.publicPerson,
      address: this.address!,
      wallet: this.walletAddress,
    };
  }
}
