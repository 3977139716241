import { Injectable } from '@angular/core';
import { CreateClientDto } from '../types/client';
import { catchError, map, Observable, of, switchMap } from 'rxjs';
import { ClientService } from '../services/ClientService';
import { getMessageFromError } from '../utils/errorMessages';
import { AlertStore } from './AlertStore';
import { ResponseSearchClient } from '../types/responseSearchClient';

@Injectable()
export class ClientStore {
  constructor(
    private api: ClientService,
    private alertStore: AlertStore,
  ) {}

  public createAccount = (payload: CreateClientDto): Observable<void> => {
    return this.api.createAccount(payload);
  };

  public verifyHasAccount = (document: string): Observable<null> =>
    this.api.verifyHasAccount({ document });

  public verifyDuplicatedEmailAndPhoneNumber = (
    email: string,
    phoneNumber: string,
  ): Observable<null> => this.api.verifyHasAccount({ phoneNumber, email });

  public searchClientInfo = (
    document: string,
  ): Observable<ResponseSearchClient> =>
    this.api.searchClientInfo(document).pipe(
      catchError((error) => {
        console.log('error', error.error.message);
        if (error.error.message === 'timeout') throw error;
        const messageKey = getMessageFromError(error);
        this.alertStore.alertError(messageKey);
        throw error;
      }),
    );
}
