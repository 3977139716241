import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
})
export class NavigationBarComponent {
  private readonly activeColor = 'white';
  private readonly defaultColor = 'lightgray';

  constructor(private router: Router) {}

  public get colorPurchase(): string {
    return this.purchaseIsActive ? this.activeColor : this.defaultColor;
  }

  public get colorStatement(): string {
    return this.statementIsActive ? this.activeColor : this.defaultColor;
  }

  public get purchaseIsActive(): boolean {
    return this.router.url === '/';
  }

  public get statementIsActive(): boolean {
    return this.router.url === '/statement';
  }

  public openPurchase = () => {
    if (this.purchaseIsActive) return;

    this.router.navigate(['/']);
  };

  public openStatement = () => {
    this.router.navigate(['/statement']);
  };
}
