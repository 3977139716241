import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[pleaseFocus]',
})
export class FocusDirective implements OnInit {
  @Input('pleaseFocus')
  public shouldFocus = true;

  constructor(private elementRef: ElementRef) {}

  public ngOnInit(): void {
    if (!this.shouldFocus) return;

    this.elementRef.nativeElement.focus();
  }
}
