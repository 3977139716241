import { Component, EventEmitter, Input, Output } from '@angular/core';
import { validate as validateBtcAddress } from 'bitcoin-address-validation';
import { isAddress as isEthAddress } from 'web3-validator';

@Component({
  selector: 'app-wallet-address',
  templateUrl: './wallet-address.component.html',
  styleUrls: ['./wallet-address.component.scss'],
})
export class WalletAddressComponent {
  private _val: string = '';

  public error = '';

  @Input()
  public set walletAddress(val: string) {
    this.value = val;
  }

  @Input()
  public label!: string;

  @Output()
  public walletAddressChange = new EventEmitter<string>();

  @Output()
  public onComplete = new EventEmitter<void>();

  @Input()
  public autoFocus = false;

  public get value(): string {
    return this._val;
  }

  public set value(v: string) {
    if (this._val === v) return;

    this._val = v;
    this.error = '';

    if (!this.addressIsValid(v)) return;

    this.walletAddressChange.emit(v);
    this.onComplete.emit();
  }

  public validateHasError = () => {
    if (!this._val) {
      this.error = 'error.wallet.required';
      return;
    }

    if (!this.addressIsValid(this._val)) {
      this.error = 'error.wallet.invalid';
      return;
    }
  };

  private addressIsValid = (val: string): boolean => {
    const validTronAddress = new RegExp('T[A-Za-z1-9]{33}').test(val);
    const validEthAddress = isEthAddress(val);
    const validBitcoinAddress = validateBtcAddress(val);

    return validTronAddress || validEthAddress || validBitcoinAddress;
  };
}
