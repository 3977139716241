import { Pipe, PipeTransform } from '@angular/core';
import { TranslatorService } from '../services/TranslatorService';

@Pipe({
  name: 't',
})
export class TranslatorPipe implements PipeTransform {
  constructor(private translator: TranslatorService) {}

  public transform(label?: string): string {
    if (!label) return '';

    return this.translator.translate(label);
  }
}
