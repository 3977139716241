import { Component, EventEmitter, Input, Output } from '@angular/core';
import { validCnpj, validCpf } from '../../../../models/utils/cpfCnpjValidator';

export enum FillDocumentType {
  individual = 'individual',
  both = 'both',
}

@Component({
  selector: 'app-document-field',
  templateUrl: './document-field.component.html',
  styleUrls: ['./document-field.component.scss'],
})
export class DocumentFieldComponent {
  private _val: string = '';

  public error = '';

  @Input()
  public set document(val: string) {
    this.value = val;
  }

  @Input('type')
  public fillDocumentType: FillDocumentType = FillDocumentType.both;

  @Input()
  public label!: string;

  @Input()
  public autoFocus = false;

  @Output()
  public documentChange = new EventEmitter<string>();

  @Output()
  public onComplete = new EventEmitter<void>();

  public get value(): string {
    return this._val;
  }

  public set value(v: string) {
    if (this._val === v) return;

    this._val = v;
    this.error = '';

    const cpfIsValid = validCpf(v);
    const cnpjIsValid = validCnpj(v);
    if (!cpfIsValid && !cnpjIsValid) return;

    this.documentChange.emit(v);
    this.onComplete.emit();
  }

  public validateHasError = () => {
    if (!this._val) {
      this.error = 'error.document.required';
      return;
    }

    if (this._val.length === 11 && !validCpf(this._val)) {
      this.error = 'error.document.invalidIndividual';
      return;
    }

    if (this._val.length === 14 && !validCnpj(this._val)) {
      this.error = 'error.document.invalidCompany';
      return;
    }

    if (![11, 14].includes(this._val.length)) {
      this.error = 'error.document.invalid';
      return;
    }
  };

  public get mask(): string {
    return {
      [FillDocumentType.both]: '000.000.000-00||00.000.000/0000-00',
      [FillDocumentType.individual]: '000.000.000-00',
    }[this.fillDocumentType];
  }

  public isEthAddress = (address: string) => {
    if (!/^(0x)?[0-9a-f]{40}$/i.test(address)) {
      return false;
    } else if (
      /^(0x)?[0-9a-f]{40}$/.test(address) ||
      /^(0x)?[0-9A-F]{40}$/.test(address)
    ) {
      return true;
    }

    return false;
  };
}
