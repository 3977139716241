import { Component, OnDestroy, OnInit } from '@angular/core';
import { AlertPayload, AlertStore } from '../../../../models/store/AlertStore';
import { Subscription } from 'rxjs';

const timeLivespanAlert = 10;

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit, OnDestroy {
  public alert?: AlertPayload;
  public show = false;
  private alertSubscription?: Subscription;
  private timeoutPointer?: NodeJS.Timeout;

  constructor(private store: AlertStore) {}

  public get alertStyle(): string {
    if (!this.alert) return '';

    return this.alert.type;
  }

  public ngOnInit(): void {
    this.alertSubscription = this.store.lastAlert$.subscribe((x) => {
      this.alert = x;

      if (!x) return;

      if (!!this.timeoutPointer) clearTimeout(this.timeoutPointer);

      this.show = true;
      this.timeoutPointer = setTimeout(() => {
        this.show = false;
      }, timeLivespanAlert * 1000);
    });
  }

  public ngOnDestroy(): void {
    this.alertSubscription?.unsubscribe();
  }
}
