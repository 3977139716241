import { Component, Input } from '@angular/core';
import { staticPix } from 'pix-qrcode';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-qr-code-pix',
  templateUrl: './qr-code-pix.component.html',
  styleUrls: ['./qr-code-pix.component.scss'],
})
export class QrCodePixComponent {
  public qrCodeImage = '';

  @Input()
  public set address(val: string) {
    this.generateQrCode(val).then((x) => (this.qrCodeImage = x));
  }

  private generateQrCode = async (address: string): Promise<string> => {
    const pixQrCode = await staticPix({
      pixKey: address,
      description: 'DLM Deposit',
      merchant: 'DLM Technologia',
      merchantCity: 'Sao Paulo',
      transactionId: uuidv4().replace(/-/gi, '').slice(-20),
      amount: '1000.00',
    });

    return pixQrCode.qrcode;
  };
}
