import {
  HttpClient,
  HttpContext,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { map, Observable, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { getMessageFromError } from '../utils/errorMessages';
import { AlertStore } from '../store/AlertStore';
import { TranslatorService } from './TranslatorService';
import { apiResponseFormatter } from '../utils';

type HttpOptions = {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  observe: 'response';
  context?: HttpContext;
  params?:
    | HttpParams
    | {
        [param: string]:
          | string
          | number
          | boolean
          | ReadonlyArray<string | number | boolean>;
      };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
};

@Injectable()
export class Api {
  constructor(
    private http: HttpClient,
    private translator: TranslatorService,
    private alertService: AlertStore,
  ) {}

  private get options(): HttpOptions {
    return {
      headers: {
        'Cache-Control': 'no-cache',
      },
      observe: 'response',
    };
  }

  public get = <T>(url: string, showError = true): Observable<T> =>
    this.tapAndMap<T>(this.http.get<T>(url, this.options), showError);

  public post = <U>(url: string, body: any, showError = true): Observable<U> =>
    this.tapAndMap(this.http.post<U>(url, body, this.options), showError);

  public put = <T, U>(url: string, body: T, showError = true): Observable<U> =>
    this.tapAndMap(this.http.put<U>(url, body, this.options), showError);

  public patch = <T, U>(
    url: string,
    body: T,
    showError = true,
  ): Observable<U> =>
    this.tapAndMap(this.http.patch<U>(url, body, this.options), showError);

  public delete = <T, U>(
    url: string,
    body: T,
    showError = true,
  ): Observable<U> =>
    this.tapAndMap(
      this.http.delete<U>(url, { ...this.options, body }),
      showError,
    );

  private tapAndMap = <T>(
    x: Observable<HttpResponse<T>>,
    showError: boolean,
  ): Observable<T> => {
    const notifyError = (e: HttpErrorResponse): void => {
      if (!showError) return;

      const messageKey = getMessageFromError(e);
      this.alertService.alertError(messageKey);
    };

    const extractBody = <T>(res: HttpResponse<T>): T => {
      return apiResponseFormatter(res.body) as T;
    };

    return x.pipe(tap({ error: notifyError }), map(extractBody));
  };
}
