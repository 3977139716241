import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { AlertStore } from '../../../../models/store/AlertStore';

@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
})
export class LabelComponent {
  constructor(
    private clipboard: Clipboard,
    private alertStore: AlertStore,
  ) {}

  @Input()
  public text!: string;

  @Input()
  public content!: string | null;

  @Input()
  public copyContent?: string;

  public onCopyClick(): void {
    const text = this.copyContent!;

    this.clipboard.copy(text);
    this.alertStore.showCopiedToClipBoardToast(this.text);
  }
}
